import * as React from 'react'
import _ from 'lodash'

import Icons from './Icons'

type SocialLinkType = {
  name: string
  link: string
}

type SocialLinksProps = {
  socialLinks: SocialLinkType[]
}

const SocialLinks = ({ socialLinks, ...props }: SocialLinksProps) => {
  const links: Record<string, string> = {}
  // STODONEXT
  _.each(
    socialLinks,
    ({ name, link }: SocialLinkType) => (links[`svg_${name}`] = link)
  )

  return <Icons links={links} {...props} />
}

export default SocialLinks
