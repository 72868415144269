import React from 'react'
import { Box, BoxProps } from '@components/UI'
import styled from 'styled-components'

import theme from '@style/theme'

import {
  FaFacebookF,
  FaInstagram,
  FaSpotify,
  FaSoundcloud,
  FaYoutube,
  FaBandcamp,
  FaApple
} from 'react-icons/fa'

import DeezerCircle from './svg/deezer_circle.svg'
// @ts-ignore
import SpotifyCircle from './svg/spotify_circle.svg'
// @ts-ignore
import SoundcloudCircle from './svg/soundcloud_circle.svg'
// @ts-ignore
import BandcampCircle from './svg/bandcamp_circle.svg'
// @ts-ignore
import ItunesCircle from './svg/apple_circle.svg'
// @ts-ignore
import YoutubeCircle from './svg/youtube_circle.svg'
// @ts-ignore
import FacebookCircle from './svg/facebook_circle.svg'
// @ts-ignore
import InstagramCircle from './svg/instagram_circle.svg'
// @ts-ignore
import DownloadCircle from './svg/download_circle.svg'
// @ts-ignore
import BeatportCircle from './svg/beatport_circle.svg'

const icons: { [index: string]: JSX.Element } = {
  fa_spotify: FaSpotify,
  fa_soundcloud: FaSoundcloud,
  fa_bandcamp: FaBandcamp,
  fa_itunes: FaApple,
  fa_youtube: FaYoutube,
  fa_facebook: FaFacebookF,
  fa_instagram: FaInstagram,
  svg_spotify: SpotifyCircle,
  svg_soundcloud: SoundcloudCircle,
  svg_bandcamp: BandcampCircle,
  svg_itunes: ItunesCircle,
  svg_youtube: YoutubeCircle,
  svg_facebook: FacebookCircle,
  svg_instagram: InstagramCircle,
  svg_deezer: DeezerCircle,
  svg_beatport: BeatportCircle,
  svg_download: DownloadCircle
}

const Icon = ({
  href,
  name,
  ...props
}: { href?: string; name: string } & BoxProps) => {
  const ChosenIcon = styled(icons[name]).attrs({
    color: theme.colors[props.color] || theme.colors.primary
  })`
    width: ${props.width || '100%'};
    height: ${props.width || '100%'};
  `

  // STODO ...props might cause a conflict with props.color here?
  return (
    <Box {...props}>
      <a href={href}>
        <ChosenIcon />
      </a>
    </Box>
  )
}

export default Icon
