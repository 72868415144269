import * as React from 'react'
import _ from 'lodash'

import { Flex, FlexProps } from '@components/UI'
import Icon from './Icon'

// STODO pr={0} for last <Icon> element
const Icons = ({
  links,
  iconWidth,
  iconMaxWidth,
  iconColor,
  spaceBetween = 2,
  ...props
}: {
  links: Record<string, string>
  iconWidth: string | number
  iconMaxWidth: string | number
  iconColor: string
  spaceBetween: string | number
} & FlexProps) => {
  return (
    <Flex {...props}>
      {_.map(links, (link, name) =>
        link ? (
          <Icon
            key={name}
            width={iconWidth}
            maxWidth={iconMaxWidth}
            color={iconColor}
            mr={spaceBetween}
            href={link}
            name={name}
          />
        ) : null
      )}
    </Flex>
  )
}

export default Icons
