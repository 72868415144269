import * as React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import Layout from '@components/Layout'
import { Link, H3, H4 } from '@components/UI'
import { SocialLinks } from '@components/social'

import { Query } from '@generated/graphql'

const Contact = ({ data }: { data: Query }) => {
  const { contactText, email } = data.allMarkdownRemark.nodes[0].frontmatter
  const socialLinks = data.site.siteMetadata.socialLinks

  return (
    <Layout title="Contact">
      <H3 pb={2}>Contact Us</H3>
      <H4 pb={5}>
        {contactText}
        <Link href={`mailto:${email}`}>{email}</Link>
      </H4>
      <H3 pb={2}>Social Media</H3>
      <SocialLinks
        pt={2}
        socialLinks={socialLinks}
        iconWidth="38px"
        spaceBetween={3}
      />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.contact.md/" } }
    ) {
      nodes {
        frontmatter {
          contactText
          email
        }
      }
    }
    site {
      siteMetadata {
        socialLinks {
          name
          link
        }
      }
    }
  }
`
